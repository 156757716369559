.infoList {
  &__list {
    border-bottom: 2px solid $gray_01;
  }
  &__link {
    text-align: right;
    max-width: 1000px;
    height: 20px;
    margin: 0 auto;
    padding: 30px 10px 30px;
    @include medium {
      padding: 30px 10px 50px;
    }
  }
}

.blogList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include medium {
    justify-content: flex-start;
    margin: 0 -20px;
  }
  &__list {
    width: 100%;
    margin-bottom: 50px;
    @include medium {
      padding: 0 20px;
      width: 50%;
    }
    @include large {
      width: 33.333%;
    }
  }
  &__link {
    padding: 30px 0 0;
    text-align: right;
    height: 20px;
  }
}

.blogContentList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include medium {
    margin: 0 -20px;
    justify-content: flex-start;
  }
  &__list {
    width: 100%;
    margin-bottom: 50px;
    @include medium {
      padding: 0 20px;
      width: 50%;
    }
    @include large {
      width: 33.333%;
    }
  }
}
