#pagetop {
    height: 100%;
}

.wrapper {
    background-color: $white;
    margin-top: 50px;

    @include medium {
        margin-top: 60px;
    }

    &--gray {
        background-color: $gray_01;
    }
}

// 共通メインコンテンツ
.mainContents {
    &--white {
        background-color: $white;
    }

    &--gray {
        background-color: $gray_01;
    }

    &__inner {
        max-width: 1280px;
        margin: 0 auto;
        padding: 15px;

        @include medium {
            padding: 40px;
        }
    }
}

// ページ内の各コンテンツ大枠
.contentSection {
    &--white {
        background-color: $white;
    }

    &--gray {
        background-color: $gray_01;
    }
}


.contentSectionStd {
    padding: 40px 10px;

    @include medium {
        padding: 50px 40px;
    }

    &__wrapper {
        max-width: 980px;
        margin: 0 auto;
    }

    &__title {
        margin-bottom: 30px;

        @include medium {
            margin-bottom: 40px;
        }
    }
}

.contentSectionInfoTop {
    &__title {
        padding: 40px 0 0;

        @include medium {
            padding: 50px 0 10px;
        }
    }
}

.contentSectionInfo {
    padding-bottom: 50px;
}

.regularSection {
    margin-top: 40px;
    padding-bottom: 50px;
}


// 各ページのタイトル部分
.pageTitle {
    font-family: $font-rubik;
    color: $main_text;
    text-align: center;
    letter-spacing: 5px;
    @include fts(30, 30);

    @include medium {
        @include fts(40, 40);
    }
}

.noItem {
    text-align: center;
    margin-top: 50px;

    &__text {
        color: $main_text;
        letter-spacing: 2px;
        @include fts(14, 14);

        @include medium {
            @include fts(16, 16);
        }
    }
}