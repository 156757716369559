// information
.infoCard {
	padding: 30px 10px;
	max-width: 1000px;
	margin: 0 auto;
	&__wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@include large {
			flex-wrap: nowrap;
		}
	}
	&__tag {
		text-align: center;
		width: 145px;
		color: $white;
		background-color: $main_text;
		font-family: $font-rubik;
		margin-right: 20px;
		@include fts(16,16);
		padding: 5px 10px;
		margin-bottom: 10px;
		@include large {
			@include fts(18,18);
			padding: 5px 15px;
			margin-bottom: 0;
		}
	}
	&__title {
		width: 835px;
		@include fts(18,28);
		color: $main_text;
		font-weight: bold;
	}
	&__text {
		margin-top: 10px;
		letter-spacing: 2px;
		@include fts(14,30);
		text-align: justify;
		@include medium {
			margin-top: 20px;
			letter-spacing: 3px;
			@include fts(15,34);
		}
		p {
			color: $main_text;
		}
		a {
			color: $main_text;
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}
}

// info_detail
.infoContentCard {
	max-width: 1000px;
	border-bottom: 1px solid $gray_02;
	padding: 0 0 30px;
	margin: 0 auto 30px;
	@include medium {
		padding: 0 0 50px;
		margin: 0 auto 50px;
	}
}


// blog
.blogCard {
	&__img {
		width: 100%;
		padding-top: 66%;
		margin-bottom: 20px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	&__main {
		color: $main_text;
	}
	&__date {
		font-family: $font-rubik;
		@include fts(18,18);
	}
	&__title {
		font-weight: bold;
		@include fts(18,28);
	}
}
