.mainVisual {
	&__wrapper {
		max-width: 1280px;
		margin: 0 auto;
		padding: 0 15px 15px;
		@include medium {
			padding: 0 40px 40px;
		}
	}
	img {
		width: 100%;
	}
}

.profileSection {
	color: $main_text;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	@include large {
		justify-content: space-between;
		flex-wrap: nowrap;
	}
	&__img {
		width: 200px;
		height: auto;
		margin-right: 0;
		margin-bottom: 30px;
		@include medium {
			margin-right: 50px;
			margin-bottom: 0;
		}
	}
	&__name {
		margin-right: 0;
		margin-bottom: 20px;
		text-align: center;
		@include large {
			margin-right: 30px;
			margin-bottom: 0;
			text-align: justify;
		}
		.nameText {
			padding-bottom: 10px;
			p {
				@include fts(20,20);
				margin-bottom: 10px;
			}
			&--en {
				font-family: $font-rubik;
			}
		}
		.historyText {
			@include fts(14,30);
		}
	}
	&__text {
		max-width: 470px;
		@include fts(14,24);
		letter-spacing: 1.5px;
	}
}


.moreLink {
	display: inline-block;
	@include fts(18,18);
	color: $main_text;
	font-family: $font-rubik;
	.icon {
		width: 15px;
		height: 15px;
		fill: &$main_text;
	}
}
