.pageControl {
  display: flex;
  justify-content: center;
  margin: 0 -13px;
  @include medium {
    margin: 0 -20px;
  }
  &__list {
    height: 15px;
    padding: 0 13px;
    @include medium {
      padding: 0 20px;
    }
    a {
      display: block;
      height: 100%;
      color: $main_text;
      font-family: $font-rubik;
      @include fts(13,13);
      @include medium {
        @include fts(15,15);
      }
    }
    .icon {
      fill: $main_text;
      width: 7px;
      height: 7px;
      @include medium {
        width: 10px;
        height: 10px;
      }
    }
  }
}
