.wp-pagenavi {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-rubik;
  @include fts(15,15);
  & > * {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 8px 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  a {
    display: block;
    color: $main_text;
  	@include hoverNoFocus {
  	border-bottom: 1px solid $main_text;
  	}
  }
  .current {
    color: $white;
    background-color: $main_text;
  }
}
