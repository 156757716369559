html {
	background-color: $body_background;
	overflow-y: auto;
	text-align: justify;
	height: 100%;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "palt";
	font-family: $font-default;
	letter-spacing: .03em;
	padding:0;
	margin:0;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	word-wrap: break-word;
	overflow-wrap: break-word;
	@include IE11 {
		font-feature-settings: normal;
		height: 100%;
	}
	@include edge {
		font-feature-settings: "palt";
	}
}

*, *:before, *:after {
	box-sizing: border-box;
}

.page {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	&__contents {
		flex: 1;
	}
}