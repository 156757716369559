.footer {
	height: 95px;
	width: 100%;
	background-color: $white;
	&__inner {
		height: 100%;
		max-width: 1310px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		padding: 37px 10px;
		position: relative;
	}
	&__pagetop {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		@include large {
			right: 20px;
		}
		a {
			display: inline-block;
			.icon {
				width: 25px;
				height: 25px;
				fill: $main_text;
			}
		}
	}
}

.copyText {
	margin-left: 25px;
	@include fts(12,12);
	font-family: $font-rubik;
	color: $main_text;
}
