// ホバーしたら半透明になる
.linkOpacity {
	display: block;
	@include hoverNoFocus {
		opacity: .8;
	}
}

// ホバーしたらアンダーライン
.linkUnder {
	display: block;
	@include hoverNoFocus {
	border-bottom: 1px solid $main_text;
	}
}
