.articleSection {
	max-width: 700px;
	margin: 0 auto;
	&__wrapper {
		border-bottom: 1px solid $gray_02;
		margin-bottom: 30px;
		@include medium {
			margin-bottom: 50px;
		}
	}
	&__title {
		text-align: center;
		margin-bottom: 30px;
		@include medium {
			margin-bottom: 40px;
		}
		h2 {
			color: $main_text;
			@include fts(20,30);
			font-weight: bold;
			margin-bottom: 20px;
		}
	}
	&__date {
		color: $main_text;
		font-family: $font-rubik;
		@include fts(18,18);
	}
	&__img {
		width: 100%;
		height: auto;
		margin-bottom: 30px;
		@include medium {
			margin-bottom: 50px;
		}
	}
	&__text {
		margin-bottom: 30px;
		@include medium {
			margin-bottom: 50px;
		}
		p {
			color: $main_text;
			text-align: justify !important;
			letter-spacing: 2px;
			@include fts(14,30);
			@include medium {
				letter-spacing: 2.5px;
				@include fts(15,34);
			}
		}
		img {
			display: block;
			margin: 0 auto;
		}
		a {
			color: $main_text;
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}
}
