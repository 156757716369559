@mixin fts($fts, $lin:16) {
	$linS: $lin / $fts;
	font-size: #{$fts}px;
	font-size: rem-calc($fts);
	line-height: $linS;
}

@mixin small {
	@media screen and #{breakpoint(small)} {
		@content;
	}
}
@mixin medium {
	@media screen and #{breakpoint(medium)} {
		@content;
	}
}

@mixin large {
	@media screen and #{breakpoint(large)} {
		@content;
	}
}
@mixin xlarge {
	@media screen and #{breakpoint(xlarge)} {
		@content;
	}
}

@mixin retina {
	@media screen and (min-resolution: 2dppx){
		@content;
	}
}

@mixin IE11 {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

@mixin edge {
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

@mixin hover {
	&:hover, &:active, &:focus {
		@content;
	}
}

@mixin hoverNoFocus {
	&:hover, &:active {
		@content;
	}
}

@mixin maxWidth {
	width: 100%;
	@include large {
		max-width: 1120px;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: auto;
		margin-right: auto;
	}
}

@mixin abridgement($line-height,$font-size,$lines,$color){
	line-height: $line-height;
	max-height: $font-size*$line-height*$lines+px;
	overflow: hidden;
	position: relative;
	word-break: break-all;
	text-align:justify;
	&:before,
	&:after{
		position: absolute;
	}
	&:before{
		content: '...';
		background: $color;
		right: 0;
		text-align: right;
		width:1em;
		top:$font-size*$line-height*($lines - 1) +px;
		background: $color;
	}
	&:after {
		content: '';
		height: 100%;
		width: 100%;
		z-index: 2;
		background: $color;
	}
}

@mixin dogEar {
	content: "";
	position: absolute;
	top:0;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 16px 16px 0 0;
	border-color: $blue_01 transparent transparent transparent;
}

@mixin boxSelectButton {
	border: 1px solid $gray_04;
	width: 100%;
	background-color: $white;
	position: relative;
	border-radius: 4px;
	cursor: pointer;
	@include fts(15,20);
	color: $gray_01;
	font-family: $font-mplus;
	font-weight: 700;
	&::before,
	&::after {
		content: "";
		position: absolute;
	}
	&::before {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: $pink_01;
		top: calc(50% - 7px);
		right: 6px;
	}
	&::after {
		border-top: 2px solid $white;
		border-right: 2px solid $white;
		width: 6px;
		height: 6px;
		transform: rotate(135deg);
		top: calc(50% - 4px);
		right: 10px;
	}
}

@mixin largeHeaderSection {
	position: relative;
	overflow: visible;
	margin-bottom: 20px;
	&::before {
		content: "";
		position: absolute;
		bottom: -22px;
		left: 50%;
		margin-left: -11px;
		border: 11px solid transparent;
		border-top: 11px solid #FFF;
		z-index: 2;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -24px;
		left: 50%;
		margin-left: -12px;
		border: 12px solid transparent;
		border-top: 12px solid $gray_04;
		z-index: 1;
	}
	&__header {
		color: $main_text;
		border-bottom: 1px solid $gray_04;
		padding: 20px 10px 0;
		@include large {
			padding: 30px 40px 0;
		}
	}
	&__wrapper {
		padding: 10px 10px 20px;
		@include large {
			padding: 30px 40px 40px;
		}
	}
}
