.header {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: rgba(255,255,255,.8);
	z-index: 5;
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		max-width: 1280px;
		margin: 0 auto;
		padding: 0 15px;
		@include medium {
			height: 60px;
			padding: 0 50px;
		}
	}
	&__logo {
		.titleLogo {
			&__image {
				display: block;
				width: auto;
				height: 25px;
				@include medium {
					height: 33px;
				}
			}
		}
	}
}

.headerNav {
	.gNavList {
		display: flex;
		align-items: center;
		li {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
		a {
			display: block;
			color: $main_text;
			font-family: $font-rubik;
			@include fts(21,21);
		}
		.icon {
			width: 28px;
			height: 28px;
			fill: $main_text;
			vertical-align: middle;
		}
	}
}

.headerNavSP {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	.gNavListSP {
		li {
			text-align: center;
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}
		a {
			display: inline-block;
			color: $main_text;
			font-family: $font-rubik;
			@include fts(24,24);
		}
		.icon {
			width: 25px;
			height: 25px;
			fill: $main_text;
		}
	}
}

.burgerButton {
	.icon {
		width: 30px;
		height: 30px;
		fill: $main_text;
	}
}

.closeButton {
	background-color: $white;
	position: absolute;
	top: 0;
	right: 5px;
	padding: 5px;
	@include medium {
		top: 5px;
		right: 40px;
	}
	.icon {
		width: 35px;
		height: 35px;
		fill: $main_text;
	}
}
