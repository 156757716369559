/* Custom colors ▼
-------------------------------- */
$white: #fff;
$black: #000;

$main_text: #333;

$gray_01: #F8F8F8; // 背景
$gray_02: #CCCCCC; // ボーダー



/* Theme setup ▼
-------------------------------- */
$global-font-size: 100%;

// body
$body_background: $white;

// fonts
$font-default: "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W3", "ＭＳ Ｐゴシック", "Helvetica Neue", Arial, sans-serif !default;
$font-alt: "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HiraMinProN-W3","HGS明朝E","HG明朝E","ＭＳ Ｐ明朝" ,"MS PMincho","ＭＳ 明朝",serif;
$font-rubik: "Rubik";
